<template>
    <div class="">
        <Loader v-if="loading" />
        <!-- Modal -->
        <!-- 1.1 - Add Project Name -->
        <b-modal id="modal-add-project" title="Create a New Project" @hide="hideProjectModal">
            <b-form b-form @submit="(e) => showSelectionModal(e)">
                <div class="mb-20">
                    <b-form-input autofocus v-model="project_name" placeholder="Enter a Project Name"
                        :state="project_name === '' ? null : nameState"
                        aria-describedby="project-name-help project-name-feedback" />
                    <b-form-invalid-feedback id="project-name-feedback">
                        Min. 5 and Max. 50 characters. Alphanumberics, special symbols are allowed, no leading/trailing spaces.
                    </b-form-invalid-feedback>
                    <b-form-valid-feedback id="project-name-help">
                        Looks good!
                    </b-form-valid-feedback>
                </div>

                <b-form-select
                    v-model="selected_efdb_log_id"
                    :options="efdbs"
                    class="mb-3"
                    value-field="efdb_log_id"
                    text-field="efdb_name"
                    disabled-field="notEnabled"
                >
                    <template #first>
                        <b-form-select-option value="" disabled>-- Please select an EFDB --</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form>
            <template #modal-footer>
                <div class="w-100 text-center">
                    <b-button pill class="px-3 py-2" variant="outline-primary" size="sm" type="submit"
                        :disabled="!validateStepOne" @click="(e) => showSelectionModal(e)">
                        Next
                    </b-button>
                    <b-button pill class="px-3 py-2" variant="outline-primary" size="sm" @click="hideProjectModal"
                        type="button">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- 1.2 - Clone Project -->
        <b-modal id="modal-clone-project" title="Create a Intervention Project" @shown="showCloneProjectModal" @hide="hideCloneProjectModal">
            <b-form b-form @submit="cloneProject()">
                <div class="mb-20">
                    <b-form-input autofocus v-model="intervention_project_name"
                        :state="intervention_project_name === '' ? null : nameStateCloneProject"
                        aria-describedby="project-name-help project-name-feedback" />
                    <b-form-invalid-feedback id="project-name-feedback">
                        Min. 5 and Max. 50 characters. Alphanumberics, special symbols are allowed, no leading/trailing spaces.
                    </b-form-invalid-feedback>
                    <b-form-valid-feedback id="project-name-help">
                        Looks good!
                    </b-form-valid-feedback>
                </div>

                <b-form-select
                    v-model="intervention_project_efdb_log_id"
                    :options="efdbs"
                    class="mb-3"
                    value-field="efdb_log_id"
                    text-field="efdb_name"
                    :disabled="true"
                >
                </b-form-select>
            </b-form>
            <template #modal-footer>
                <div class="w-100 text-center">
                    <b-button pill class="px-3 py-2" variant="outline-primary" size="sm" type="submit"
                        :disabled="!validateStepOneCloneProject" @click="cloneProject()">
                        Submit
                    </b-button>
                    <b-button pill class="px-3 py-2" variant="outline-primary" size="sm" @click="hideCloneProjectModal"
                        type="button">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- 2 - Select Project Category -->
        <b-modal id="modal-project-category" :title="getProjectDetails()" v-model="addSelectionModal" size="lg"
                @hide="hideSelectionModal">
            <div v-for="(item, i) in collection" :key="item.id">
                <b-row>
                    <!-- Life Cycle Stage -->
                    <b-col :xl="collection.length > 1 ? 3 : 4" :md="collection.length > 1 ? 3 : 4">
                        <b-dropdown :text="item.selected_lcs" :variant="
                            item.selected_lcs == 'Select Life Cycle Stage'
                                ? 'light'
                                : 'primary'
                        " class="m-2" :options="lc_stages">
                            <b-dropdown-item v-for="data in lc_stages" :key="data" :value="data"
                                @click="collection[i].selected_lcs = data; getEmissionCategoryTypes(data, i)">{{ data }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <!-- Emission Category -->
                    <b-col :xl="collection.length > 1 ? 3 : 4" :md="collection.length > 1 ? 3 : 4">
                        <b-dropdown :disabled="item.selected_lcs == 'Select Life Cycle Stage'"
                            :text="item.selected_ef_type" :variant="
                                item.selected_ef_type == 'Select Emission Category'
                                    ? 'light'
                                    : 'primary'
                            " class="m-2">
                            <b-dropdown-item v-for="option of ef_types" :key="option" :value="option"
                                @click="collection[i].selected_ef_type = option">
                                {{ option }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <!-- Activity Group -->
                    <b-col :xl="collection.length > 1 ? 3 : 4" :md="collection.length > 1 ? 3 : 4">
                        <b-dropdown :text="item.selected_ag"
                            :disabled="item.selected_ef_type == 'Select Emission Category'" :variant="
                                item.selected_ag == 'Select Activity Group'
                                    ? 'light'
                                    : 'primary'
                            " class="m-2">
                            <b-dropdown-item v-for="option of activity_groups" :key="option" :value="option"
                                @click="(collection[i].selected_ag = option), (getAllActivitySubGroupsAndActivityTypes(option, i)), (item.accordionShow = true)">
                                {{ option }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="item.activity_groups.length == 0" value="" :disabled="true" />
                            <!--  No activity groups under {{ item.selected_ef_type }}
                        </b-dropdown-item-->
                        </b-dropdown>
                    </b-col>
                    <b-col v-if="collection.length > 1" :xl="3" :md="3" class="accord-position">
                        <b-row>
                            <b-button type="button" @click="item.accordionShow = !item.accordionShow">
                                <b-icon :icon="item.accordionShow ? 'chevron-down' : 'chevron-up'" aria-hidden="true" />
                            </b-button>
                            <!-- Delete Collection -->
                            <b-button type="button" @click="collection.splice(i, 1), (item.accordionShow = false)">
                                <b-icon icon="dash-circle" aria-hidden="true" />
                            </b-button>
                        </b-row>
                    </b-col>
                </b-row>

                <b-collapse :visible="item.accordionShow">
                    <b-row>
                        <div class="step-3">
                            <!-- Activity Sub-Group -->
                            <div class="select-activities sub-group">
                                <div class="selected-sub-group">
                                    <li v-for="option of item.selected_sub_ag_n_act_typ" :key="option" @click="
                                        () => (
                                            (item.selected_sub_ag_n_act_typ = item.selected_sub_ag_n_act_typ.filter(
                                                (val) =>
                                                    val.activity_sub_group != option.activity_sub_group
                                            )),
                                            handle_Sel_Act_SG(
                                                item.selected_sub_ag_n_act_typ,
                                                i,
                                                true
                                            )
                                        )
                                    ">
                                        <div>{{ option.activity_sub_group }}</div>
                                        <span>X</span>
                                    </li>
                                </div>
                                <!-- :options="[...$store.getters.allActivitySubGroups]" -->
                                <multiselect v-model="item.selected_sub_ag_n_act_typ" label="activity_sub_group"
                                    track-by="activity_sub_group" :multiple="true"
                                    :options="collection[i]['sub_ag_n_act_typ']"
                                    @input="(val) => handle_Sel_Act_SG(val, i, false)" placeholder="" class="mul-select"
                                    openDirection="below">
                                    <template slot="selection" slot-scope="{ values, isOpen }">
                                        <span class v-if="values.length"></span>
                                        <span class v-if="!isOpen">
                                            Select more Activity Sub-Groups
                                        </span>
                                    </template>
                                </multiselect>
                            </div>
                            <!-- Activity Types -->
                            <div class="select-activities types">
                                <div class="selected-sub-group">
                                    <div v-for="option of item.selected_activity_types" :key="option.name">
                                        <li :class="option.selected ? 'activity-type blue' : 'activity-type'"
                                            @click="option.selected = !option.selected">
                                            {{ getInitials(option.name) }}
                                        </li>
                                        <span>{{ option.name }}</span>
                                    </div>
                                </div>
                                <!-- :multiple="true" :options="all_activity_types" placeholder="" class="mul-select" -->
                                <!-- :multiple="true" :options="item.selected_sub_ag_n_act_typ['activity_type']" placeholder="" class="mul-select" -->
                                <multiselect :value="item.selected_activity_types"
                                    @input="(val) => handle_Sel_Act_Type(val, i)" label="name" track-by="name"
                                    :multiple="true" :options="getActivityTypes(i)" placeholder="" class="mul-select"
                                    openDirection="below">
                                    <template slot="selection" slot-scope="{ values, isOpen }">
                                        <span class v-if="values.length"></span>
                                        <span class v-if="!isOpen">
                                            Select more Activity Types
                                        </span>
                                    </template>
                                </multiselect>
                                <div class="w-100 text-center">
                                    <b-button pill class="m-2" variant="outline-primary" size="sm"
                                        @click="selectActivityTypes(item)" type="button">
                                        Select All Activity Types
                                    </b-button>
                                    <b-button pill class="m-2" variant="outline-primary" size="sm"
                                        @click="deselectActivityTypes(item)" type="button">
                                        Deselect All Activity Types
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </b-row>
                </b-collapse>
            </div>

            <template #modal-footer>
                <div class="w-100 text-center">
                    <b-button pill class="px-3 py-2" variant="outline-primary" size="sm" @click="createProject()"
                        type="button" :disabled="!disableNext">
                        Submit
                    </b-button>
                    <b-button pill class="outline-px-3 py-2" variant="outline-primary" size="sm" type="button"
                        @click="addNewCollection()" :disabled="!disableNext">
                        Add another Life Cycle Stage
                    </b-button>
                    <b-button pill type="button" class="px-3 py-2" variant="outline-primary" size="sm"
                        @click="hideSelectionModal">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-modal>
        <!-- Modal End -->
    </div>
</template>
<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

import Loader from "../components/Loader";

Vue.component("multiselect", Multiselect);
export default {
    components: { Multiselect, Loader },
    name: "SelectionModal",
    props: {
        display: {
            type: String,
        },
        openModal: {
            type: Function,
        },
    },
    computed: {
        nameState() {
            return this.checkNameforAsciiCharacters(this.project_name);
        },
        nameStateCloneProject() {
            return this.checkNameforAsciiCharacters(this.intervention_project_name);
        },
        validateStepOne() {
            return this.checkNameforAsciiCharacters(this.project_name) && this.selected_efdb_log_id;
        },
        validateStepOneCloneProject() {
            return this.checkNameforAsciiCharacters(this.intervention_project_name) && this.intervention_project_efdb_log_id;
        },
    },
    data() {
        return {
            focusTrap: true,
            modalOpen: false,
            addSelectionModal: false,
            loading: false,
            sub_groups_loading: false,
            project_name: "",
            intervention_project_name: "",
            non_valid_name: false,
            project_id: "",
            disableNext: false,
            validCollection: false,
            collection: [],
            sub_ag_n_act_typ: [],
            addCollection: {
                accordionShow: false,
                activity_groups: [],
                sub_ag_n_act_typ: [],
                activity_types: [],
                selected_lcs: "Select Life Cycle Stage",
                selected_ef_type: "Select Emission Category",
                selected_ag: "Select Activity Group",
                selected_sub_ag_n_act_typ: [],
                selected_activity_types: [],
            },
            lc_stages: [],
            ef_types: [],
            activity_groups: [],
            all_activity_types: [],
            life_cycle_stages: [
                {
                    value: "LC1 Material Acquisition",
                    text: "LC1 Material Acquisition",
                }, {
                    value: "LC2 Production",
                    text: "LC2 Production",
                }, {
                    value: "LC3 Distribution & Storage",
                    text: "LC3 Distribution & Storage",
                }, {
                    value: "LC4 Use",
                    text: "LC4 Use",
                }, {
                    value: "LC5 End-of-Life",
                    text: "LC5 End-of-Life",
                }
            ],
            efdbs: [],
            selected_efdb_log_id: "",
            intervention_project_efdb_log_id: ""
        };
    },
    methods: {
        loadEFDBs() {
            let promise = this.axios.get("/uploadEFDB")
            promise.then((res) => {
                this.efdbs = res.data;
            })
            promise.catch((error) => {
                console.log("loadEFDBs() error", error);
            });
        },
        getEFDBName() {
            for(let idx in this.efdbs) {
                if(this.efdbs[idx].efdb_log_id == this.selected_efdb_log_id) {
                    return " (EFDB: " + this.efdbs[idx].efdb_name + ")";
                }
            }
            return "";
        },
        getProjectDetails() {
            return this.project_name + this.getEFDBName();
        },
        checkNameforAsciiCharacters(name) {
            if (name.charCodeAt(0) === 32) return false

            if (name.trim().length < 5) return false

            let flag = true;
            for (let i = 0; i < name.length; i++) {
                if (name.charCodeAt(i) < 32 || name.charCodeAt(i) > 125) {
                    flag = false;
                    break;
                }
            }
            return flag
        },

        hideProjectModal() {
            this.$root.$emit("bv::hide::modal", "modal-add-project");
            this.project_name = "";
            this.selected_efdb_log_id = "";
        },
        showCloneProjectModal() {
            this.intervention_project_name = this.$store.getters.cloneProject.project_name;
            this.intervention_project_efdb_log_id = this.$store.getters.cloneProject.efdb_log_id;
        },
        hideCloneProjectModal() {
            this.$root.$emit("bv::hide::modal", "modal-clone-project");
            this.intervention_project_name = "";
            this.intervention_project_efdb_log_id = "";
        },
        async showSelectionModal(e){
            if(this.checkNameforAsciiCharacters(this.project_name)) {
                await this.getAllActivitySubGroups();

                e.preventDefault();
                this.$root.$emit("bv::show::modal", "modal-project-category");
            } else {
                e.preventDefault();
            }
        },
        async hideSelectionModal() {
            this.$root.$emit("bv::hide::modal", "modal-project-category");
            this.collection = [];
            this.collection.push({
                accordionShow: false,
                activity_groups: [],
                sub_ag_n_act_typ: [],
                activity_types: [],
                selected_lcs: "Select Life Cycle Stage",
                selected_ef_type: "Select Emission Category",
                selected_ag: "Select Activity Group",
                selected_sub_ag_n_act_typ: [],
                selected_activity_types: [],
            });

            let activitySubGroups = [...this.$store.getters.allActivitySubGroups];
            for (let subGroup of activitySubGroups) {
                for (let type of subGroup.activity_type) {
                    type.selected = true;
                }
            }

            // await this.$store.commit("setAllActivitySubGroups", activitySubGroups);
            this.hideProjectModal();

            console.log("hideSelectionModal", this.collection);
        },

        //   onChange Selected Activity Sub Group
        handle_Sel_Act_SG(val, i) {
            let selected_activity_types = [];
            for(let activity_type_dict of val) {
                selected_activity_types.push(...activity_type_dict["activity_type"]);
            }
            // selected_activity_types = [
            //     ...this.collection[i].selected_activity_types,
            // ];
            selected_activity_types = selected_activity_types.filter(
                (el) => el.selected
            );
            this.collection[i].selected_activity_types = [];
            this.collection[i].selected_sub_ag_n_act_typ = val;
            let unq_arr = val
                .map((x) => x.activity_type)
                .concat()
                .flat();

            let result = unq_arr.filter(function (a) {
                var key = a.name + "|" + a.selected;
                if (!this[key]) {
                    this[key] = true;
                    return true;
                }
            }, Object.create(null));
            for (let name of result) {
                this.collection[i].selected_activity_types = this.addType(
                    selected_activity_types,
                    name
                );
            }
        },
        handle_Sel_Act_Type(val, i) {
            let data = [...val];
            let newVal = data.map((el) => {
                let obj = {};
                obj.name = el.name;
                obj.selected = el.selected;
                return obj;
            });
            this.collection[i].selected_activity_types = [...newVal];
        },
        addType(arr, name) {
            const found = arr.some(
                (el) =>
                    el.name === name.name ||
                    (el.name === name.name && el.selected === name.selected)
            );
            if (!found)
                arr.push(name);
            return arr;
        },
        addNewCollection() {
            this.collection.push({ ...this.addCollection });
        },
        createProject() {
            this.loading = true;
            let postData = {
                project_name: this.project_name.trim(),
                efdb_log_id: this.selected_efdb_log_id
            };
            this.axios
                .post("/addProject", postData)
                .then(async (res) => {
                    console.log("createProject res", res);
                    this.loading = false;
                    this.project_id = res.data.project._id;
                    await this.$store.commit("setProject", res.data.project);
                    await this.$store.commit("setHighlightsProject", res.data.project);
                    await this.$root.$emit("setProjectDetails", res.data.project);
                    this.addSelection();
                })
                .catch((error) => {
                    console.log("createProject error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        cloneProject() {
            this.loading = true;
            let postData = {
                project_name: this.intervention_project_name,
            };
            this.axios
                .post(`/project/${this.$store.getters.cloneProject._id}/clone`, postData)
                .then(async (res) => {
                    console.log("createProject res", res);
                    this.loading = false;
                    this.project_id = res.data.project._id;

                    await this.$store.commit("setProject", res.data.project);
                    await this.$store.commit("setHighlightsProject", res.data.project);
                    await this.$root.$emit("setProjectDetails", res.data.project);

                    this.$router.push("table");
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("createProject error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        addSelection() {
            let postData = [];
            for (let obj of this.collection) {
                let data = {
                    project_id: this.project_id,
                    lifecycle_stage: obj.selected_lcs,
                    emission_category: obj.selected_ef_type,
                    activity_group: obj.selected_ag,
                    activity_subgroup_list: obj.selected_sub_ag_n_act_typ
                        .map((x) => x.activity_sub_group)
                        .concat()
                        .flat(),
                    activity_type_list: this.getSelectedSubActTyp(
                        obj.selected_activity_types
                    ),
                };
                postData.push(data);
            }
            console.log("addSelection 1: ", { postData });

            const AllPromise = [];
            this.loading = true;
            for (let post of postData) {
                AllPromise.push(this.axios.post("/addSelection", post));
            }
            Promise.all(AllPromise)
                .then((res) => {
                    console.log("addSelection res", res);
                    this.$router.push("table");
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("addSelection error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        getSelectedSubActTyp(val) {
            let data = [];
            for (let x of val) {
                if (x.selected) {
                    data.push(x.name);
                }
            }
            return data;
        },
        //  Enable 'Next' button once Data is Validated
        disableNextbtn() {
            let valid = true;
            if (this.collection.length != 0) {
                for (let obj of this.collection) {
                    valid =
                        obj.selected_lcs != "Select Life Cycle Stage" &&
                        obj.selected_ef_type != "Select Emission Category" &&
                        obj.selected_ag != "Select Activity Group" &&
                        obj.selected_activity_types.some((obj) => {
                            return obj.selected;
                        }) &&
                        valid;
                }
            } else {
                valid = false;
            }
            this.disableNext = valid;
            console.log("disable :: ", valid);
        },
        removeActivitySubGroup(index, i) {
            this.collection[i].selected_sub_ag_n_act_typ.splice(index, 1);
        },
        getInitials(type) {
            return type.substring(0, 2);
        },
        async getLifecycleStages() {
            this.loading = true;
            await this.axios
                .get("/getLcStages")
                .then((res) => {
                    console.log("getLcStages res", res);
                    this.lc_stages = res.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("geLcStages error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        async getEmissionCategoryTypes(selected_lifecycle_stage, i) {
            this.loading = true;
            await this.axios
                .get("/getEfTypes?lifecycle_stage=" + encodeURIComponent(selected_lifecycle_stage))
                .then((res) => {
                    console.log("getEfTypes res", res);

                    this.collection[i].selected_ef_type = "Select Emission Category";
                    this.ef_types = res.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("getEfTypes error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        //getActivityGroups(ef_type, i) {
        getActivityGroups() {
            this.loading = true;
            this.axios
                //.get("/getActivityGroups?ef_type=" + ef_type)
                .get("/getActivityGroups")
                .then((res) => {
                    console.log("getActivityGroups res", res);
                    this.activity_groups = res.data.data;
                    this.loading = false;
                    //let val = res.data.data;
                    //this.collection[i].activity_groups = val;
                    //this.collection[i].selected_ef_type = ef_type;
                    //this.collection[i].selected_ag = "Select Activity Group";
                    //this.getAllActivitySubGroupsAndActivityTypes(ef_type, i);
                })
                .catch((error) => {
                    console.log("getActivityGroups error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        getAllActivitySubGroupsAndActivityTypes(activity_group, i) {
            this.loading = true;
            this.axios
                .get("/getAllActivitySubGroupsAndActivityTypes?activity_group="+encodeURIComponent(activity_group)+"&efdb_log_id="+this.selected_efdb_log_id)
                .then((res) => {
                    console.log("getAllActivitySubGroupsAndActivityTypes  res", res);
                    let val = res.data.data;
                    this.collection[i].sub_ag_n_act_typ = val;
                    this.collection[i].selected_sub_ag_n_act_typ = val;
                    let unq_arr = val
                        .map((x) => x.activity_type)
                        .concat()
                        .flat();

                    let result = unq_arr.filter(function (a) {
                        a.selected = false;
                        var key = a.name + "|" + a.selected;
                        if (!this[key]) {
                            this[key] = true;
                            return true;
                        }
                    }, Object.create(null));

                    this.collection[i].selected_activity_types = result;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("getAllActivitySubGroupsAndActivityTypes error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        async getAllActivitySubGroups() {
            // if (!this.$store.getters.allActivitySubGroups) {
            this.loading = true;
            this.sub_groups_loading = true;
            await this.axios
                .get("/getAllActivitySubGroups?efdb_log_id="+this.selected_efdb_log_id)
                .then(async (res) => {
                    console.log("getAllActivitySubGroups  res", res);
                    let val = res.data.data;
                    await this.$store.commit("setAllActivitySubGroups", val);
                    this.loading = false;
                    this.sub_groups_loading = false;
                })
                .catch((error) => {
                    console.log("getAllActivitySubGroups error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                    this.sub_groups_loading = false;
                });
            // }
        },
        async getAllActivityTypes() {
            this.loading = true;
            await this.axios
                .get("/getAllActivityTypes")
                .then((res) => {
                    console.log("getAllActivityTypes res", res);
                    let data = [...res.data.data];
                    let all_activity_types_temp = [];
                    for (let types of data) {
                        let obj = {
                            name: types,
                            selected: true,
                        };
                        all_activity_types_temp.push(obj);
                    }
                    this.all_activity_types = [...all_activity_types_temp];
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("getAllActivityTypes error", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        getActivityTypes(i) {
            let activity_type_list = [];
            if(this.collection[i]["selected_sub_ag_n_act_typ"].length == 0) {
                return [];
            }

            for(let tdict of this.collection[i]["selected_sub_ag_n_act_typ"]) {
                let tlist = tdict["activity_type"];
                activity_type_list.push(...tlist);
            }
            activity_type_list = JSON.parse(JSON.stringify(activity_type_list));
            for(let activity_type of activity_type_list) {
                activity_type["selected"] = false;
            }

            return activity_type_list;
        },
        selectActivityTypes(item) {
            for (let option of item.selected_activity_types) {
                option.selected = true;
            }
        },
        deselectActivityTypes(item) {
            for (let option of item.selected_activity_types) {
                option.selected = false;
            }
        }
    },
    created: async function () {
        this.collection.push({ ...this.addCollection });
        this.loadEFDBs();
        await this.getLifecycleStages();
        //await this.getEmissionCategoryTypes();
        await this.getActivityGroups();
        // await this.getAllActivityTypes();
        // await this.getAllActivitySubGroups();
    },
    mounted() {
        this.$root.$on('bv::modal::shown', async() => {
            await this.loadEFDBs();

            let ele = document.querySelector("#modal-project-category___BV_modal_header_ button.close");
            if(ele)
                ele.setAttribute("tabindex", -1);
        })
    },
    watch: {
        collection: {
            handler: function (val, oldVal) {
                console.log("col new:", val, oldVal);
                this.disableNextbtn();
            },
            deep: true,
        },
    },
};
</script>
<style scoped lang="scss">
.h-100-cust {
    height: 100vh;
}

.float {
    position: fixed;
    bottom: 70px;
    right: 20px;
}

.card {
    height: 159px;
    width: 191px;
    margin: auto;
}

.proj-card {
    .card-body {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .card-title {
        font-size: 25px !important;
        margin-bottom: 0px !important;
        color: black;
    }
}

.cust-border {
    border-color: black !important;
    border-radius: 0px !important;
}

.step-3 {
    margin: 30px;
    min-height: 300px;
    height: 100%;
    width: 100%;
}

.title {
    font-weight: 400;
    font-size: 20px;
}

.select-activities {
    min-height: 250px;
    border-color: black;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}

.selected-sub-group {
    display: flex;
    list-style: none;
    flex-wrap: wrap;

    li {
        border: 1px solid #707070;
        border-radius: 5px;
        margin: 5px;
        padding: 0px 10px;
        color: #007cba;

        span {
            margin-left: 10px;
            color: #676161;
            cursor: pointer;
        }
    }
}

.select-activities.types {
    border-top: 0px;

    .selected-sub-group {
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 5.3em !important;
            margin: 10px 0;

            li.activity-type {
                border: 1px solid #707070;
                width: 72px;
                height: 76px;
                border-radius: 50%;
                margin: 0 auto;
                padding: 0px 10px;
                color: #676161;
                font-size: 2.4em !important;
                font-weight: 400 !important;
                text-align: center;
                cursor: pointer;
            }

            span {
                margin-left: 10px;
                color: #676161;
                // cursor: pointer;
                font-size: 12px;
                text-align: center !important;

                display: block;
                word-wrap: break-word;
                width: 72px;
                white-space: normal;
            }
        }
    }
}

.accord-position {
    left: 45px;
    top: 7px;
}
</style>

<style lang="scss">
.multiselect__content-wrapper {
    min-height: 100px !important;
    max-height: 200px !important;
}

.dropdown-menu.show {
    max-height: 98vh !important;
    overflow-y: auto;
}

.modal-content {
    border-radius: 31px !important;
}

.btn.dropdown-toggle.btn-primary {
    min-width: 250px !important;
    max-width: 250px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.btn.dropdown-toggle.btn-light {
    min-width: 250px !important;
}

.modal-lg {
    max-width: 1050px !important;
}

.modal-title {
    margin-left: auto;
    font-size: 20px;
    color: black;
    font-weight: 400;
}

.blue {
    background-color: #b0e1e9; //original
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: rgb(0, 0, 0);
    background-color: #ffffff;
    border-color: #ffffff;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>